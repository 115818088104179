<template>
  <Page class="page dirver-info-page">
    <Navbar title="司机信息">
      <template #right>
        <span class="text_primary px-10" @click="handleRemoveFromFleet">移出车队</span>
      </template>
    </Navbar>
    <div class="page-content">
      <van-cell-group class="py-24">
        <div class="text-main bg-white pb-16 px-16">
          <Icon name="sfz"></Icon>
          <span class="font-14 pl-8">司机信息:</span>
        </div>
        <div class="px-16 pb-24">
          <div class="text_muted pb-8">身份证照片</div>
          <div class="idCard-img-wrap flex flex-row justify-space-between">
            <div class="preview-img" @click="handleImagePreview(0)">
              <van-image class="w-full h-full" fit="cover" :src="imageUrl(driverRef.idCardFrontFilename)"></van-image>
              <div class="text">
                点击查看
              </div>
            </div>
            <div class="preview-img" @click="handleImagePreview(1)">
              <van-image class="w-full h-full" fit="cover" :src="imageUrl(driverRef.idCardBackFilename)"></van-image>
              <div class="text">
                点击查看
              </div>
            </div>
          </div>
        </div>

        <van-cell title="手机号" :value="driverRef.phone"></van-cell>
        <van-cell title="身份证姓名" :value="driverRef.name"></van-cell>
        <van-cell title="身份证号码" :value="driverRef.idCard"></van-cell>
        <van-cell title="身份证地址" :value="driverRef.address"></van-cell>
      </van-cell-group>

      <van-cell-group class="py-24 mt-16">
        <div class="text-main bg-white pb-16 px-16">
          <Icon name="jsz"></Icon>
          <span class="font-14 pl-8">驾驶证:</span>
        </div>
        <div class="px-16 pb-24">
          <div class="text_muted pb-8">驾驶证照片</div>
          <div class="license-img-wrap">
            <div class="preview-img w-full" @click="handleImagePreview(2)">
              <van-image class="w-full h-full" fit="cover" :src="imageUrl(driverRef.licenseFilename)"></van-image>
              <div class="text">
                点击查看
              </div>
            </div>
          </div>
        </div>

        <van-cell
          title="驾驶证编号
"
          :value="driverRef.driverLicenseNumber"
        ></van-cell>
        <van-cell
          title="准驾车型
"
          :value="driverRef.driveType"
        ></van-cell>
        <van-cell
          title="驾驶证有效期自
"
          :value="formatToDateTime(driverRef.driverLicenseStartDate, 'yyyy-MM-dd')"
        ></van-cell>
        <van-cell
          title="驾驶证有效期至"
          :value="formatToDateTime(driverRef.driverLicenseEndDate, 'yyyy-MM-dd')"
        ></van-cell>
        <van-cell
          title="发证机关
"
          :value="driverRef.driverLicenseIssueOrganization"
        ></van-cell>
      </van-cell-group>

      <van-cell-group class="py-24 mt-16">
        <div class="text-main bg-white pb-16 px-16">
          <Icon name="cyz"></Icon>
          <span class="font-14 pl-8">从业资格证:</span>
        </div>
        <div class="px-16 pb-24">
          <div class="text_muted pb-8">从业资格证</div>
          <div class="qualification-img-wrap ">
            <div class="preview-img w-full" @click="handleImagePreview(3)">
              <van-image
                class="w-full h-full"
                fit="cover"
                :src="imageUrl(driverRef.qualificationLicenceMainFilename)"
              ></van-image>
              <div class="text">
                点击查看
              </div>
            </div>
          </div>
        </div>

        <van-cell title="从业资格证号" :value="driverRef.qualificationNumber"></van-cell>
      </van-cell-group>
    </div>
  </Page>
</template>

<script>
import { defineComponent, ref, onBeforeMount, computed, nextTick } from '@vue/composition-api';
import { queryDriverApi, queryDriverByIdApi } from '@/api/driver';
import { removeDriverFromFleetApi } from '@/api/carrier';
import { getThumbnailFileTemporaryUrlListApi } from '@/api/common';
import { useRoute, useRouter } from '@/utils/compsitionHack';
import { Cell, CellGroup, Image, ImagePreview, Dialog } from 'vant';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { useUserStore } from '@/store/user';
import { useCarrierStore } from '@/store/carrier';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image
  },
  setup() {
    const driverRef = ref({});
    const { query } = useRoute();
    const imageMapRef = ref({});
    const router = useRouter();

    const userStore = useUserStore();
    const carrierStore = useCarrierStore();

    const imageUrl = computed(() => key => {
      if (imageMapRef.value[key]) {
        return imageMapRef.value[key].url;
      } else {
        return null;
      }
    });

    const imageThumbnail = key => {
      if (imageMapRef.value[key]) {
        return imageMapRef.value[key].url;
      } else {
        return null;
      }
    };

    const handleImagePreview = index => {
      ImagePreview({
        images: [
          imageThumbnail(driverRef.value.idCardFrontFilename),
          imageThumbnail(driverRef.value.idCardBackFilename),
          imageThumbnail(driverRef.value.licenseFilename),
          imageThumbnail(driverRef.value.qualificationLicenceMainFilename)
        ],
        startPosition: index
      });
    };

    const handleRemoveFromFleet = () => {
      Dialog.confirm({
        title: '提示',
        message: '是否确定移除该司机？',
        confirmButtonText: '移除'
      }).then(async () => {
        const carrierId = userStore.userInfo.carrierId;
        const driverId = driverRef.value.id;
        try {
          await removeDriverFromFleetApi({ carrierId, driverId });
          await carrierStore.getFleetDriversAction();
          console.log(2);
          nextTick(() => {
            console.log(3);
            router.push(PageEnum.CARRIER_FLEET);
          });
        } catch (error) {
          console.log('err is ->', error);
        }
      });
    };

    onBeforeMount(async () => {
      try {
        // const { data } = await queryDriverApi({ driverPhone: query.id });
        const { data } = await queryDriverByIdApi({ id: query.ids });
        if (data) {
          driverRef.value = data;
          const filenames = [
            data.idCardFrontFilename,
            data.idCardBackFilename,
            data.licenseFilename,
            data.qualificationLicenceMainFilename,
            data.qualificationLicenceSideFilename
          ];
          const res = await getThumbnailFileTemporaryUrlListApi({ filenames, process: 'image/resize,w_200' });
          imageMapRef.value = res.data;
        }
      } catch (error) {
        console.log('err is ->', error);
      }
    });

    return {
      driverRef,
      imageUrl,
      handleImagePreview,
      formatToDateTime,
      handleRemoveFromFleet
    };
  }
});
</script>

<style lang="less" scoped>
.dirver-info-page {
  min-height: 100vh;
  .idCard-img-wrap {
    & > div {
      width: 1.63rem;
      height: 1.22rem;
    }
  }

  .license-img-wrap,
  .qualification-img-wrap {
    & > div {
      height: 1.76rem;
    }
  }
}
</style>
